<template>
  <div
    :key="key"
    style="overflow-y: auto !important;"
  >
    <div class="welcome">
      <div style="padding-bottom: 60px">
        <!----- 언어 선택 셀렉트 ------->
        <div class="welcome__select--lang">
          <select
            v-model="language"
            class="lang_select"
          >
            <option
              value="en-US"
              :selected="true"
            >
              English
            </option>
            <option
              value="ko-KR"
            >
              한국어
            </option>
            <option value="zh-CN">
              Chinese
            </option>
          </select>
        </div>

        <!----- img title ------->
        <div class="welcome__img">
          <img src="@/assets/images/main/svg/welcome_visual_img_02.svg">
        </div>
        <div class="welcome__title">
          TN-BOX <span>MPS</span> System
        </div>
        <div class="welcome__subtitle">
          프린터 임대 서비스에 딱 맞춘 통합관리 솔루션!
        </div>

        <!----- 카운트 ------->
        <div class="welcome__count">
          <div class="welcome__count--item">
            <div>임대 계약 수</div>
            <div>{{ contractCount.toLocaleString() }}</div>
          </div>
          <div class="welcome__count--item">
            <div>실시간 모니터링 수</div>
            <div>{{ monitoringCount.toLocaleString() }}</div>
          </div>
        </div>

        <!----- 로그인 ------->
        <div class="welcome__login">
          <button
            v-if="!userManager.validate"
            @click="onClickLogin"
          >
            {{ '로그인' | translate }}
          </button>
        </div>
      </div>


      <!--이용약관_end-->
      <div class="footer">
        <div class="policy">
          <a
            href="javascript:void(0)"
            @click="onClickTermsOfUse(TERMS_OF_USE.SERVICE_AGREEMENT)"
          >{{ '이용약관' | translate }}</a>
          <a
            href="javascript:void(0)"
            @click="onClickTermsOfUse(TERMS_OF_USE.PRIVATE_POLICY)"
          >{{ '개인정보처리방침' | translate }}</a>
          <a
            href="javascript:void(0)"
            @click="onClickTermsOfUse(TERMS_OF_USE.LOCATION_AGREEMENT)"
          >{{ '위치정보이용약관' | translate }}</a><!--임대회원만 노출 됨-->
        </div>
        <p>
          {{ '상호 : (주) 틴텍씨엔씨 ㅣ 사업자등록번호 : 808-87-01665' | translate }} <br>
          {{ '주소 : (우)07631 서울특별시 강서구 마곡동 799-14' | translate }}<br>
          {{ '메트로비즈타워 504호' | translate }}
        </p>

        <span>COPYRIGHT © 2019 Tintec cnc ALL RIGHTS RESERVED</span>
      </div>
    </div>
  </div>
</template>

<script>
import {NOTICE_SORT} from "@/constants/notice-value";
import {PATH} from "@/constants";
import VuexProps from "@/framework/vuex/VuexProps";
import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
import common from "@/assets/plugins/common/functions";

export default {
  name: "Welcome",
  mixins: [VuexProps, TextAreaAutogrow],
  data() {
    return {
      announcementsInfo: [], // 제품정보
      announcementsTrends: [], // 업계동향
      language: this.$route.query.lang || this.$language(),
      key: 0,
      contractCount: 0,
      monitoringCount: 0,
    };
  },
  computed: {
    validLanguage() {
      switch (this.language) {
        case 'ko-KR':
        case 'en-US':
        case 'zh-CN':
          return this.language;
      }
      return 'en-US';
    },
  },
  watch: {
    language() {
      window.location.href = "/welcome?lang=" + this.language;
    },
  },
  created() {
    this.languageTranslate(...common.extractLanguage(this.validLanguage));
  },
  mounted() {
    this.processLoadOnce();
  },
  methods: {
    async languageTranslate(languageCode, countryCode) {
      this.getPrinterContractCount()
      this.getMonitoringPrinterCount()
      const response = await this.$request(PATH.PUBLIC.WELCOME.LANGUAGE_TRANSLATE)
        .setObject({
          languageCode: languageCode,
          countryCode: countryCode
        })
        .enqueue();
      this.$translationManager.save(response.data);
      this.key++;
    },

    /**
     * 로그인 링크가 클릭되었습니다.
     */
    onClickLogin() {
      this.routerManager.push({name: 'NewLogin'});
    },

    /**
     * 로그아웃 링크가 클릭되었습니다.
     */
    onClickLogout() {
      this.$userManager.logout();
      this.routerManager.push({name: 'NewLogin'});
    },

    /**
     * 공지사항 목록
     */
    onClickWelcomeNoticeList(sortCd) {
      if (this.$userManager.validate) {
        this.routerManager.push({name: 'WelcomeNoticeList', params: {sortCd: sortCd}});
      } else {
        this.dialogValidate();
      }
    },

    dialogValidate() {
      this.$dialog()
        .message(this.$translate("게시글은 로그인시 자세히 볼 수 있습니다."))
        .buttonPositive(this.$translate("로그인/회원가입"), () => {
          this.routerManager.push({name: "NewLogin"});
        })
        .buttonNegative(this.$translate("취소"))
        .build()
        .show();
    },

    /**
     * 공지사항 상세
     */
    onClickWelcomeNoticeDetail(item) {
      if (this.$userManager.validate) {
        this.routerManager.push({
          name: 'WelcomeNoticeDetail',
          params: {
            noticeId: item.keys.noticeId,
          }
        });
      } else {
        this.dialogValidate();
      }
    },

    /**
     * 이용약관/개인정보처리방침/위치정보이용약관 클릭시 호출
     */
    onClickTermsOfUse(which) {
      this.routerManager.push({name: 'TermsOfUse', params: {which: which}})
    },

    processLoadOnce() {
      this.getPrinterContractCount()
      this.getMonitoringPrinterCount()
    },

    /**
     * 제품정보 불러오기
     */
    async loadProductInformation() {
      const response = await this.$request(PATH.PUBLIC.WELCOME.LIST_NOTICE)
        .setObject({
          pageNumber: 1,
          pageSize: 4,
          sortCd: NOTICE_SORT.PRODUCT_INFORMATION,
          countryCode: this.validLanguage.slice(3, 5),
        })
        .enqueue();
      this.announcementsInfo = response.data;
    },

    /**
     * 업계동향 불러오기
     */
    async loadIndustryTrends() {
      const response = await this.$request(PATH.PUBLIC.WELCOME.LIST_NOTICE)
        .setObject({
          pageNumber: 1,
          pageSize: 4,
          sortCd: NOTICE_SORT.INDUSTRY_TRENDS,
          countryCode: this.validLanguage.slice(3, 5),
        })
        .enqueue();
      this.announcementsTrends = response.data;
    },

    onClickLogo() {
      if (this.$userManager.validate) {
        if (this.$userManager.isCommunityUser) {
          location.reload();
        } else if (this.$userManager.isRentalUser) {
          this.$router.replace({name: "WelcomeDivider"});
        }
      } else {
        location.reload();
      }
    },


    async getPrinterContractCount() {
      try {
        this.contractCount = 0
        const res = await this.$request(PATH.PUBLIC.PRINTER_All_CONTRACT)
          .get()
          .enqueue();

        this.timerNumberAddCntForPrintContract(res)
      } catch (e) {
        // 정보를 못 불러왔을 때는 임의의 숫자 넣어줌
        this.contractCount = 0;
      }

      // this.$refs['contractCounter'].counter();
    },

    timerNumberAddCntForPrintContract(cnt) {
      let divide = cnt.toString().length;

      const interval = setInterval(() => {
        const cntLength = this.contractCount.toString().length - 1
        if (this.contractCount > 10) {
          this.contractCount += Math.ceil((cnt - this.contractCount) / (10 * (divide - cntLength)))
        } else {
          this.contractCount += 1
        }

        if (this.contractCount >= cnt) clearThisInterval()
      }, 40)
      const clearThisInterval = () => {
        clearInterval(interval)
      }
    },

    async getMonitoringPrinterCount() {
      try {
        this.monitoringCount = 0
        const res = await this.$request(PATH.PUBLIC.MONITORING_PRINTER_All_CONTRACT)
          .get()
          .enqueue();

        this.timerNumberAddCntForMonitoringPrinter(res);
      } catch (e) {
        // 정보를 못 불러왔을 때는 임의의 숫자 넣어줌
        this.monitoringCount = 0;
      }
    },

    timerNumberAddCntForMonitoringPrinter(cnt) {
      let divide = cnt.toString().length;

      const interval = setInterval(() => {
        const cntLength = this.monitoringCount.toString().length - 1
        if (this.monitoringCount > 10) {
          this.monitoringCount += Math.ceil((cnt - this.monitoringCount) / (10 * (divide - cntLength)))
        } else {
          this.monitoringCount += 1
        }

        if (this.monitoringCount >= cnt) clearThisInterval()
      }, 40)
      const clearThisInterval = () => {
        clearInterval(interval)
      }
    },
  }
}
</script>

<style scoped>
/*TN-BOX MOBILE MAIN_start*/
/* .mbody {
     position: inherit !important;
 }

 .mtop {
     width: 100%;
     background: url("/img/mbg.png") no-repeat 0 0;
     height: 334px;
     background-size: cover;
     background-position: center;
 }

 .mtop .mtheader {
     height: 56px;
     width: 100%;
 }

 .mtop .mtheader .tnboxlogo {
     text-align: center;
     padding-top: 12px;
     width: 130px;
     margin: 0 auto;
 }

 .mtop .mtheader .tnboxlogo img {
     width: 130px;
 }

 .mtop .mtheader button {
     font-size: 0.875rem;
     color: #fff;
     padding: 0 16px;
     border: 1px solid #fff;
     height: 36px;
     position: absolute;
     top: 10px;
     right: 18px;
 }

 .mtop .bantext {
     text-align: center;
     color: #fff;
 }

 .mtop .bantext h1 {
     font-size: 1.625rem;
     letter-spacing: -0.5px;
     margin-top: 20px;
 }

 .mtop .bantext h1 span {
     color: #3B93F9
 }

 .mtop .bantext p {
     font-size: 1rem;
     margin-top: 8px;
 }

 .mtop .banimg {
     margin-top: 23px
 }

 .mtop .banimg img {
     width: 120px;
 }

 @media (max-width: 320px) {
     .mtop .mtheader button {
         font-size: 0.75rem;
         color: #fff;
         padding: 0 12px;
         border: 1px solid #fff;
         height: 30px;
         float: right;
         margin: 0px 18px 0 0;
         top: 13px;
         right: 0px;
     }

     .mtop .banimg img {
         width: 87px;
     }

     .msection .mslist li a {
         text-decoration: none;
         font-size: 0.875rem;
         line-height: 20px;
         color: #000;
         letter-spacing: -0.3px;
         width: 270px !important;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         display: inline-block;

     }

 }


 .mcont {
     padding: 0 18px;
     display: block;
     overflow-x: hidden;

 }

 .msection {
     width: 100%;
     margin-top: 14px
 }

 .msection .mstop {
     border-bottom: 1px solid #ddd;
     padding-bottom: 6px;
     overflow: hidden;
     margin-bottom: 12px;
     width: 100%;
 }

 .msection .mstop .mstit {
     font-size: 1.125rem;
     color: #000;
     float: left;
     font-weight: 600;
 }

 .msection .mstop a {
     float: right;
     display: inline-block;
     padding-top: 3px;
 }

 .msection .mslist {
     width: 100%;
     padding-left: 0px !important;
 }

 .msection .mslist li {
     padding-bottom: 14px;

     list-style: none;
     text-align: left;
 }

 .msection .mslist li a {
     text-decoration: none;
     font-size: 0.875rem;
     line-height: 20px;
     color: #000;
     letter-spacing: -0.3px;
     width: 300px;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     display: inline-block;
 } */


/*TN-BOX MOBILE MAIN_end*/

/* a.more-btn {
     color: #333;
     font-size: 12px;
     font-weight: bold;
     text-decoration: none;
 }

 .lang_select {
     border: 1px solid #fff;
     color: white;
     font-size: 0.75rem;
     padding: 0 12px;
     height: 31px;
     float: left;
     margin: 12px;
     border-radius: 0;
 }

 .lang_select option {
     color: black;
 } */


/***********************************/
/*************2022.08.08**************/
/***********************************/

.welcome {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url(/img/bg_welcome.svg);
  background-repeat: no-repeat;
  font-family: "Noto Sans KR", sans-serif;
  background-size: cover;
}

.welcome__select--lang {
  text-align: right;
  padding: 10px 15px;
}

.welcome__select--lang select {
  width: 68px;
  color: #707070;
  background-image: url(/img/dashboard/dashboard_arrow.svg);
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 8px;
}

.welcome__img {
  width: 236px;
  margin: 0 auto 22px;
}

.welcome__img img {
  width: 100%;
}

.welcome__title {
  font-size: 1.75rem;
  font-weight: bold;
}

.welcome__title span {
  color: #0C98FE;
  letter-spacing: -1px;
}

.welcome__subtitle {
  font-size: 1rem;
  margin-bottom: 38px;
  letter-spacing: -1px;
}

.welcome__count {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  background-color: white;
  height: 64px;
  width: 100%;
  box-shadow: rgba(222, 235, 251, 0.8) 0px 4px 6px -1px, rgba(222, 235, 251, 0.3) 0px 2px 4px -1px;
}

.welcome__count--item {
  width: 50%;
}

.welcome__count--item div:nth-child(1) {
  color: #0C98FE;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -1px;
}

.welcome__count--item div:nth-child(2) {
  font-size: 1.125rem;
  font-weight: bold;
  color: #333;
}

.welcome__login {
  padding: 0 15px;
}

.welcome__login button {
  width: 100%;
  background-color: #0C98FE;
  color: white;
  border-radius: 3px;
  height: 50px;
}

.footer {
  padding: 20px 18px;
  width: 100%;
  background-color: #f2f2f2;
  margin-top: auto;
}

.policy {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.policy a {
  display: inline-block;
  font-size: 0.8125em;
  color: #444;
  text-decoration: none;
  padding-right: 20px;
  font-weight: bold;
}

.policy a:last-child {
  padding-right: 0px;
}

.footer p {
  text-align: left;
  font-size: 0.75rem;
  color: #202020;
  width: 100%;
}

.footer span {
  text-align: left;
  font-size: 0.7rem;
  color: #202020;
  display: block;
  padding-top: 12px;
  width: 100%;
}
</style>
